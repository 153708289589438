import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import { Container, Wrapper } from "../../components/util";
import { Breadcrumb } from "../../components/site";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const CodeOfConduct = (props) => (
  <Layout>
    <Seo
      title="Code of Conduct | FlexiTime Partner"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Breadcrumb
      parentPage="Partner Resources"
      parentPageLink="/partner-resources"
      currentPage="Partner Code of Conduct"
    />
    <Container>
      <Wrapper stackGap={80} maxWidth={1000}>
        <h1 className="-textCenter">FlexiTime Partner Code of Conduct</h1>
        <div className="font-bump">
          <p>
            <b>
              This code of conduct gives FlexiTime Partners guidance on how we
              wish to behave and interact with each other, with clients, and
              publicly, when using and promoting the suite of FlexiTime Limited
              workforce management solutions, including PayHero, Karmly and
              Droppah.
            </b>
          </p>
          <p>
            This Code of Conduct (<b>Code</b>) applies to accountants,
            bookkeepers, tax agents and other business advisors who have
            accepted the FlexiTime Partner Agreement (<b>Partners</b>) with
            FlexiTime Limited (<b>FlexiTime</b>).
          </p>
          <p>
            In addition to helping Partners manage client relationships, this
            Code is intended to provide guidance on any disputes that may arise
            in connection with FlexiTime’s software solutions (<b>Products</b>).
          </p>
          <p>&nbsp;</p>
          <h3>
            <b>Providing information about FlexiTime products</b>
          </h3>
          <p>
            Partners play a key role in educating prospective Clients about
            their options for setting up their business on FlexiTime’s Products.
            Partners should discuss and seek a written acknowledgement from
            their Client about who, between the Client and the Partner, will be
            the “Subscriber” that ultimately controls access rights to and is
            responsible for payment of the subscription (as described in the{" "}
            <Link to="/subscription-agreement?reload">
              FlexiTime Subscription Agreement
            </Link>
            ).
          </p>
          <p>
            Partners must maintain good knowledge of the FlexiTime products,
            including the ability to describe the features and functions
            available. Partners will also provide first level support to their
            clients to answer simple usability questions.
          </p>
          <p>&nbsp;</p>
          <h3>
            <b>Behavioural guidelines</b>
          </h3>
          <p>
            <b>
              Partners are expected to display behaviour that contributes to
              creating a positive environment for Clients and promote and
              support FlexiTime and our Products. This means:
            </b>
          </p>
          <ul>
            <li>
              Staying up to date with features and functionality in the
              FlexiTime products
            </li>
            <li>
              Being respectful of FlexiTime employees and products when
              interacting with support, the wider FlexiTime team or speaking
              publicly or online about FlexiTime or our Products
            </li>
            <li>
              Proactively asking for help via Support or your Partner Manager if
              you are experiencing issues or have queries about the FlexiTime
              products{" "}
            </li>
            <li>
              Gracefully accepting constructive feedback from the FlexiTime team
              and providing feedback to us in a constructive manner.
            </li>
          </ul>
          <p>
            <b>Examples of unacceptable behaviour by Partners include:</b>
          </p>
          <ul>
            <li>
              Trolling, insulting/derogatory comments, or personal attacks about
              FlexiTime, our products or employees
            </li>
            <li>Public or private harassment</li>
            <li>
              Publishing others’ private information without explicit permission
            </li>
            <li>
              Acting in a way that has the potential to damage FlexiTime, or our
              Products, or that could serve to harm our business in any way
            </li>
            <li>
              Other conduct that could reasonably be considered inappropriate in
              a professional setting.
            </li>
          </ul>
          <p>&nbsp;</p>
          <h3>
            <b>Facilitating subscription transfers</b>
          </h3>
          <p>
            Partners must take reasonable action to co-operate with any Clients
            that wish to remove the Partner from their subscription or transfer
            their subscription to a new Partner. This includes providing or
            maintaining access to the FlexiTime product subscription, or
            transferring the FlexiTime product subscription as reasonably
            necessary to efficiently facilitate the transition.
          </p>
          <p>&nbsp;</p>
          <h3>
            <b>Administration of this Code of Conduct</b>
          </h3>
          <p>
            FlexiTime expects that its Partners will observe and comply with
            this Code and that, in doing so, Partners will be able to more
            effectively manage any disputes that may occur with their Clients or
            with us in relation to FlexiTime Products.
          </p>
          <p>
            In cases of a dispute between a Partner and a Client, FlexiTime
            expects that in most cases the parties will be able to resolve the
            dispute directly between themselves (particularly if the above
            guidance is followed) before being escalated to our attention.
          </p>
          <p>&nbsp;</p>
          <h3>
            <b>Enforcement</b>
          </h3>
          <p>
            If instances of abusive or otherwise unacceptable behaviour,
            FlexiTime will enforce this Code of Conduct using the following
            process:
          </p>
          <ul>
            <li>
              <b>Reporting</b>: Where a FlexiTime Partner does not follow or
              enforce the Code of Conduct in good faith, they may be reported to
              the FlexiTime Partner Manager team by a FlexiTime staff member or
              other party.
            </li>
            <li>
              <b>Reviewing and investigating</b>: All complaints will be
              reviewed and investigated and will result in a response that is
              deemed necessary and appropriate to the circumstances. The
              FlexiTime team is obligated to maintain confidentiality with
              regard to the reporter of an incident unless the reporter is ok
              with their identity being known.
            </li>
            <li>
              <b>Warnings</b>: The FlexiTime Partner Manager team expects in
              most circumstances to give the Partner a warning before specifying
              any repercussions, however in severe cases there may not be a
              warning.
            </li>
            <li>
              <b>Repercussions</b>: The FlexiTime Partner Manager team can
              specify repercussions to the FlexiTime Partner breaching the Code.
              Repercussions may be temporary or permanent, including being
              removed as a FlexiTime Partner.
            </li>
          </ul>
        </div>
        <h6>
          Last update: <b>APRIL 30, 2021</b>
        </h6>
      </Wrapper>
    </Container>
  </Layout>
);

export default CodeOfConduct;
